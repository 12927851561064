
<template>
  <v-container >
    <v-card elevation="0" class="pt-5">
      <v-container>
        <v-row class="justify-center">
          <v-col md="6">
            <v-text-field :label="$t('name')" dense outlined color="#757575" v-model="roleName"></v-text-field>
            <!-- <v-card :loading="loadingAbilites" color="#757575">
              <v-card-title  class="d-flex justify-center align-center">
                <h3>{{ $t('powers') }}</h3>
              </v-card-title>
              <div class="create-role-chips-wrapper">
                <div class="create-role__chip-wrapper" v-for="item in allAbilites" :key="item.id">
                  <div @click="selectAbility(item)" class="create-role-chip" :class="{
                    activeChip: item.selected,
                  }">
                    {{ item.name }}
                  </div>
                </div>
              </div>
              <v-card-actions  class="d-flex justify-center align-center">
                <v-btn color="green" class="white--text" depressed width="100" :loading="submitBtnLoading"
                  @click="submitRole">{{ $t('storage') }}</v-btn>
              </v-card-actions>
            </v-card> -->
          </v-col>

        </v-row>
        <v-row>
          <v-btn color="green" class="white--text" depressed width="100" :loading="submitBtnLoading"
                  @click="submitRole">{{ $t('storage') }}</v-btn>
        </v-row>
        <v-row>
          <template>
              <v-col md="4" v-for="(value, key, idx) of abilities" :key="idx">
                <v-card class="add-user__chips-wrapper_Up">
                  <v-card-title class="justify-center">
                    <h2>{{ key }}</h2>
                  </v-card-title>
                  <v-card-text class="d-flex flex-column justify-center align-center">
                    <div  v-for="(ability, idx) in abilities[key]" :key="idx + 1" class="add-user__chip_Up">
                      <v-switch :true-value="true" :false-value="false" :input-value="selectedAbility(ability)"
                        color="green" @change="handleSwitchValue($event, ability)"></v-switch>
                      {{ ability.name }}
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </template>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import Notifications from "../../../../helpers/toast-notifications";
export default {
  data() {
    return {
      roleId: this.$route.params.id,
      abilities: [],
      selectedAbilites: [],
      toBeAddedAbilites: {},
      itemSelected: false,
      loadingAbilites: false,
      submitBtnLoading: false,
      roleName: "",
    };
  },
  methods: {
    selectAbility(item) {
      this.itemSelected = true;
      if (item.selected) {
        delete this.toBeAddedAbilites[item.id];
        item.selected = false;
      } else {
        this.toBeAddedAbilites[item.id] = true;
        item.selected = true;
      }
    },
    handleSwitchValue(switchVal, ability) {
      let abilityId = ability.id;
      if (switchVal) {
        this.toBeAddedAbilites[abilityId] = true;
      } else {
        delete this.toBeAddedAbilites[abilityId];
      }
    },
    selectedAbility(ability) {
      return this.toBeAddedAbilites.hasOwnProperty(ability.id);
    },
    async submitRole() {
      try {
        this.selectedAbilites = [];
        this.submitBtnLoading = true;
        if (!this.roleName) {
          Notifications("حقل الاسم فارغ", { timeout: 5000, rtl: true }, "info");
          return;
        }
        if (Object.keys(this.toBeAddedAbilites).length === 0) {
          Notifications(
            "لا يوجد صلاحيات لهذا الدور",
            { timeout: 5000, rtl: true },
            "info"
          );
          return;
        }
        for (let key in this.toBeAddedAbilites) {
          this.selectedAbilites.push(key);
        }
        await axios.put(`/role/${this.roleId}`, {
          name: this.roleName,
          ability: this.selectedAbilites,
        });
        Notifications(
          this.$t('edit success'),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.$router.push("/roles");
      } catch (err) {
      } finally {
        this.submitBtnLoading = false;
      }
    },
  },
  computed: {
    allAbilites() {
      if (this.itemSelected) {
        this.itemSelected = false;
        return this.abilities;
      } else {
        return this.abilities;
      }
    },
  },
  async created() {
    try {
      this.loadingAbilites = true;
      const abilites = await axios.get("/ability");
      const currentRole = await axios.get(`/role/${this.roleId}`);
      this.roleName = currentRole.data.data.name;
      currentRole.data.data.ability.forEach((el) => {
        this.toBeAddedAbilites[el.id] = true;
      });
      this.abilities = abilites.data.data;
      // this.abilities.forEach((el) => {
      //   if (this.toBeAddedAbilites[el.id]) {
      //     el.selected = true;
      //   } else {
      //     el.selected = false;
      //   }
      // });
    } catch (err) {
      console.log("err", err);
    } finally {
      this.loadingAbilites = false;
    }
  },
};
</script>

<style>
.add-user__chips-wrapper_Up {
  /* max-height: 500px; */
  height: 470px;
  overflow: scroll;
  overflow-x: hidden;
  border: 1px solid #757575;
}

.add-user__chip_Up {
  border: 1px solid #757575;
  border-radius: 10px;
  margin: 5px 0;
  /* padding: 5px 0; */
  width: 100%;
  /* height: 70%; */
  color: var(--bg-color--font);
  display: flex;
  justify-content: space-around;
  align-items: center;
}
</style>


<!-- <template>
    <v-container>
      <v-row>
        <v-col md="12">
          <v-toolbar :dark="$store.state.isDarkMode">
            <template>
              <v-tabs v-model="tab" align-with-title color="#757575">
                <v-tabs-slider></v-tabs-slider>
                <v-col md="3"></v-col>
                <v-col md="3">
                  <v-tab :href="'#' + '1'">{{
                    $t("Roles")
                  }}</v-tab>
                </v-col>
                <v-col md="3">
                  <v-tab :href="'#' + '2'">
                    {{ $t("Accounts") }}
                  </v-tab>
                </v-col>
              </v-tabs>
            </template>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-tabs-items :dark="$store.state.isDarkMode" v-model="tab">
        <v-tab-item value="1">
          <editRole v-if="tab == '1'" />
        </v-tab-item>
        <v-tab-item value="2">
          <editeAbility v-if="tab == '2'" />
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </template>
  
  <script>
  import editRole from './editRole.vue'
  import editeAbility from "./editAbility.vue";
  export default {
    components: {
        editRole,
        editeAbility,
    },
    data() {
      return {
        tab: false,
      };
    },
  };
  </script>
  
  <style>
  .registration-record__img-wrapper {
    width: 150px;
    height: auto;
    cursor: pointer;
  }
  
  .registration-record__img {
    width: 100%;
  }
  </style>
   -->